/**
 * @description All Events will be written inside this file. Format for this event are as follows
 */

const Events = {
  Locations: {
    Module: 'Location Tab',
    Screen: [
      {
        Name: 'Current',
        Events: {
          Current_TabClick: 'Current_TabClick',
          Dept_Dropdown_MenuClick: 'Dept_Dropdown_MenuClick',
          Unit_Dropdown_MenuClick: 'Unit_Dropdown_MenuClick',
          Staff_Pool_Search_FieldClick: 'Staff_Pool_Search_FieldClick',
          Staff_Member_SelectClick: 'Staff_Member_SelectClick',
          Unit_Patient_Search_FieldClick: 'Unit_Patient_Search_FieldClick',
          Assign_ButtonClick: 'Assign_ButtonClick',
          Unassign_ButtonClick: 'Unassign_ButtonClick',
          Publish_ButtonClick: 'Publish_ButtonClick',
        },
      },
      {
        Name: 'New Draft',
        Events: {
          New_Draft_TabClick: 'New_Draft_TabClick',
          Dept_Dropdown_MenuClick: 'Dept_Dropdown_MenuClick',
          Unit_Dropdown_MenuClick: 'Unit_Dropdown_MenuClick',
          Staff_Pool_Search_FieldClick: 'Staff_Pool_Search_FieldClick',
          Staff_Member_SelectClick: 'Staff_Member_SelectClick',
          Unit_Patient_Search_FieldClick: 'Unit_Patient_Search_FieldClick',
          Assign_ButtonClick: 'Assign_ButtonClick',
          Unassign_ButtonClick: 'Unassign_ButtonClick',
          Publish_ButtonClick: 'Publish_ButtonClick',
      },
    }
    ],
  },
  Patients: {
    Module: 'Patient Tab',
    Screen: {
      Name: 'Patient',
      Events: {
        Patient_TabClick: 'Patient_TabClick',
        Dept_Dropdown_MenuClick: 'Dept_Dropdown_MenuClick',
        Unit_Dropdown_MenuClick: 'Unit_Dropdown_MenuClick',
        Staff_Pool_Search_FieldClick: 'Staff_Pool_Search_FieldClick',
        Staff_Member_SelectClick: 'Staff_Member_SelectClick',
        Unit_Patient_Search_FieldClick: 'Unit_Patient_Search_FieldClick',
        Assign_ButtonClick: 'Assign_ButtonClick',
        Unassign_ButtonClick: 'Unassign_ButtonClick',
        Publish_ButtonClick: 'Publish_ButtonClick',
      },
    },
  },
  Reports: {
    Module: 'Reports Tab',
    Screen: [
      {
        Name: 'Snapshot',
        Events: {
            Snapshot_ReportClick: 'Snapshot_ReportClick',
            Unit_DropdownClick: 'Unit_DropdownClick',
            Position_DropdownClick: 'Position_DropdownClick',
            Date_FieldClick: 'Date_FieldClick',
            Time_HourClick: 'Time_HourClick',
            Time_MinuteClick: 'Time_MinuteClick',
            KeywordClick: 'KeywordClick',
            Cancel_ButtonClick: 'Cancel_ButtonClick',
            Run_ReportClick: 'Run_ReportClick',
        },
      },
      {
        Name: 'AssignmentAudit',
        Events: {
            Assignment_Audit_ReportClick: 'Assignment_Audit_ReportClick',
            Unit_DropdownClick: 'Unit_DropdownClick',
            Position_DropdownClick: 'Position_DropdownClick',
            TimeClick: 'TimeClick',
            KeywordClick: 'KeywordClick',
            Cancel_ButtonClick: 'Cancel_ButtonClick',
            Run_ReportClick: 'Run_ReportClick',
        },
      },
      {
        Name: 'MissingAssignments',
        Events: {
          Missing_Assignments_ReportClick: 'Missing_Assignments_ReportClick',
          Unit_DropdownClick: 'Unit_DropdownClick',
          Position_DropdownClick: 'Position_DropdownClick',
          TimeClick: 'TimeClick',
          Cancel_ButtonClick: 'Cancel_ButtonClick',
          Run_ReportClick: 'Run_ReportClick',
        },
      },
    ],
  },
    Manage: {
      Module: 'Manage Tab',
      Screen: [
        {
          Name: 'Users',
          Events: {
            Users_TabClick: 'Users_TabClick',
            Division_Dropdown_MenuClick: 'Division_Dropdown_MenuClick',
            Facility_Dropdown_MenuClick: 'Facility_Dropdown_MenuClick',
            Role_Dropdown_MenuClick: 'Role_Dropdown_MenuClick',
            Download_User_ListClick: 'Download_User_ListClick',
          },
        },
        {
          Name: 'Locations',
          Events: {
            Locations_TabClick: 'Locations_TabClick',
            Division_Dropdown_MenuClick: 'Division_Dropdown_MenuClick',
            Facility_Dropdown_MenuClick: 'Facility_Dropdown_MenuClick',
            Units_Dropdown_MenuClick: 'Units_Dropdown_MenuClick',
            Rooms_Dropdown_MenuClick: 'Rooms_Dropdown_MenuClick',
            Status_Dropdown_MenuClick: 'Status_Dropdown_MenuClick',
          },
        },
        {
          Name: 'Patients',
          Events: {
            Patients_TabClick: 'Patients_TabClick',
            Division_Dropdown_MenuClick: 'Division_Dropdown_MenuClick',
            Facility_Dropdown_MenuClick: 'Facility_Dropdown_MenuClick',
            Units_Dropdown_MenuClick: 'Units_Dropdown_MenuClick',
            Search_FieldClick: 'Search_FieldClick',
            Remove_ButtonClick: 'Remove_ButtonClick',
          },
        },
      ],
    },
}
export default Events;

