const initialState = {
  bedsSearchResults: [],
  draftBedSearchResults: [],
  searching: false,
  searchText: '',
  page: '',
  userSearchResults: [],
  showUnpublishedModal: false,
  disabledDropdowns: false,
  selectedFacilityCoidManagePage: null,
  slidingPane: {},
  assignmentSlidingPane: {},
  reportSlidingPane: {},
  timeOutError: false,
  processingAssign: false,
  sortedFlatList: [],
  auditReportTypeAndTime: {},
  modifyReportKeyword: '',
  snapshotData: { date: '', time: '', position: [] },
  missingAssignmentData: {
    timeIncrementInHours: '',
    timeReportGenerated: '',
    startDateTime: '',
    endDateTime: '',
  },
  flashMessage: {
    messages: [],
    type: '',
    uuid: '',
  },
  managePageDataType: 'Sites',
  skip: 0,
  take: 50,
  showAssignmentsModal: false,
  isDraftAssignment: false,
  tableCheckboxIsChecked: false,
  departmentSelected: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'manage/SET_DATA_TYPE':
      return {
        ...state,
        managePageDataType: action.payload,
      }
    case 'locations/SET_SEARCH':
      return {
        ...state,
        ...action.payload,
      }
    case 'locations/CLEAR_SEARCH':
      return {
        ...state,
        searchText: '',
        searching: false,
        bedsSearchResults: [],
        draftBedSearchResults: [],
      }
    case 'user/SET_SEARCH':
      return {
        ...state,
        ...action.payload,
      }
    case 'user/CLEAR_SEARCH':
      return { ...state, searchText: '', searching: false }
    case 'user/SET_PAGINATION':
      return {
        ...state,
        skip: action.payload.skip,
      }
    case 'site/SET_MANAGE_SITE':
      return { ...state, ...action.payload }
    case 'ui/MANAGE_SLIDING_PANE':
      return { ...state, slidingPane: action.payload }
    case 'ui/ASSIGNMENT_SLIDING_PANE':
      return { ...state, assignmentSlidingPane: action.payload }
    case 'ui/REPORTS_SLIDING_PANE':
      return { ...state, reportSlidingPane: action.payload }
    case 'ui/SET_TIMEOUT_ERROR':
      return { ...state, timeOutError: action.payload }
    case 'ui/SET_AUDIT_TYPE_AND_TIME':
      return { ...state, auditReportTypeAndTime: action.payload }
    case 'ui/CLEAR_AUDIT_TYPE_AND_TIME':
      return {
        ...state,
        auditReportTypeAndTime: {
          type: '',
          time: '',
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: '',
        },
      }

    case 'ui/SET_REPORT_KEYWORD':
      return { ...state, modifyReportKeyword: action.payload }
    case 'ui/SET_DISABLED_DROPDOWNS':
      return { ...state, disabledDropdowns: action.payload }
    case 'ui/SET_SHOW_UNPUBLISHED_MODAL':
      return { ...state, showUnpublishedModal: action.payload }
    case 'ui/CLEAR_REPORT_KEYWORD':
      return { ...state, modifyReportKeyword: '' }
    case 'ui/SET_SNAPSHOT_DATE_AND_TIME':
      return { ...state, snapshotData: action.payload }
    case 'ui/CLEAR_SNAPSHOT_DATE_AND_TIME':
      return { ...state, snapshotData: { date: '', time: '', position: [] } }
    case 'ui/SET_MISSING_ASSIGNMENT_DATA':
      return { ...state, missingAssignmentData: action.payload }
    case 'ui/CLEAR_MISSING_ASSIGNMENT_DATA':
      return {
        ...state,
        missingAssignmentData: {
          timeIncrementInHours: '',
          timeReportGenerated: '',
          startDateTime: '',
          endDateTime: '',
        },
      }
    case 'ui/FLASH_MESSAGE':
      return { ...state, flashMessage: action.payload }
    case 'ui/SHOW_ASSIGNMENTS_MODAL':
      return { ...state, showAssignmentsModal: true }
    case 'ui/HIDE_ASSIGNMENTS_MODAL':
      return { ...state, showAssignmentsModal: false }
    case 'ui/IS_DRAFT_ASSIGNMENT':
      return { ...state, isDraftAssignment: action.payload }
    case 'ui/TABLE_CHECKBOX_CHECKED':
      return { ...state, tableCheckboxIsChecked: action.payload }
    case 'ui/SET_SELECTED_DEPARTMENT':
      return { ...state, departmentSelected: action.payload }
    default:
      return state
  }
}
