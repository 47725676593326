/**
 * External Imports
 * */
import { useState, useEffect, useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'
import moment from 'moment'
import _ from 'lodash'

/**
 * Internal Imports
 * */
import { ErrorSnackbar } from '../../reusableComponents/errorPages/ErrorSnackbar'
import Events from '../../../analytics/events'
import { UnitDropdown, DeptDropdown } from '../../reusableComponents/DropDown'
import { UnpublishedChanges } from '../../reusableComponents/unpublishedChanges/UnpublishedChanges'
import Loading from '../../reusableComponents/neutronComponents/Spinner'
import AssigningToAllUnits from '../../reusableComponents/neutronComponents/assigning/AssigningToAllUnits'
import PublishDraftModal from '../../reusableComponents/publishDraftModal/PublishDraftModal'
import ManageLinks from '../../routes/ManageLinks'
import ReportsLinks from '../../routes/ReportsLinks'
import OverwritingAssignment from '../../reusableComponents/overwritingAssignment/OverwritingAssignment'

import { selectUnit } from '../../../redux/actions/units'
import { selectUserGroup } from '../../../redux/actions/groupAndStaffSlotMetaData'
import { clearAllSelectedLocationsStaff } from '../../../redux/actions/selectedStaffSlots'
import { clearAllSelectedStaff } from '../../../redux/actions/staffPool'
import { clearReportData } from '../../../redux/actions/reports'
import { setUserSettings } from '../../../redux/actions/user'
import { setSelectedStaffSlot } from '../../../redux/actions/selectedStaffSlots'
import {
  fetchLocationsWithMultipleUnits,
  fetchAllAssignments,
} from '../../../redux/actions/locations'
import {
  fetchDraftAssignments,
  saveDraftAssignments,
  unassignDraftAssignments,
  discardDraftAssignments,
  fetchPreferredDraftStaffPool,
} from '../../../redux/actions/draftAssignments'
import { fetchPreferredStaffPool } from '../../../redux/actions/facilitySchedulerUsers'
import {
  assignSlots,
  discardStagedSlots,
} from '../../../redux/actions/stagedStaffSlots'

import unitandUserGroupDropdownSelector from '../../../redux/selectors/unitandUserGroupDropdownSelector'

import { publishFunction } from '../../../utils/publish'

import ReadOnlyBanner from './ReadOnlyBanner'

/**
 * Style Imports
 * */
import {
  SubNavItem,
  NavItemText,
  TabButton,
  SubNavButtonContainer,
  AssignmentActionButtons,
  DraftButtons,
  PublishButton,
  SubNav,
} from './subNav.styles'

const SubNavigation = ({
  allUnitsSelected,
  assigning,
  authToken,
  disabledNav,
  draftAssignments,
  draftLocations,
  sites,
  siteId,
  formattedUnits,
  formattedUserGroups,
  locations,
  page,
  selectedGroup,
  selectedSlots,
  selectedStaffMember,
  selectedUnitIds,
  staffPool,
  staffSlots,
  stagedStaffSlots,
  saving,
  stagedStaffSlotErrors,
  ui,
  units,
  user,
  userSettings,
  dispatch,
  handleSlidingPane,
  ...rest
}) => {
  const [showUnpublishedChangesModal, setShowUnpublishedChangesModal] =
    useState(false)
  const [showOverlapAssignment, setShowOverlapAssignment] = useState(false)
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [initialPublish, setInitialPublish] = useState(true)
  const [selectedUnits, setSelectedUnits] = useState([])
  const [searchText, setSearchText] = useState(null)
  const [listOfUnits, setListOfUnits] = useState(formattedUnits)
  const [searching, setSearching] = useState(false)
  const [searchingDept, setSearchingDept] = useState(false)
  const [deptSearchText, setDeptSearchText] = useState(null)
  const [listOfDepts, setListOfDepts] = useState([])
  const [selectedDept, setSelectedDept] = useState({})
  const [draftButtonSelected, setDraftButtonSelected] = useState(false)
  const [currentButtonSelected, setCurrentButtonSelected] = useState(true)
  const [showAssignToAllUnits, setShowAssignToAllUnits] = useState(false)
  const [showingPublishModal, setShowingPublishModal] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [hasDraftAssignment, setHasDraftAssignment] = useState(false)
  const [isEDUnit, setIsEDUnit] = useState(false)

  // Get both site wide and unit level configuration for locked roles
  const lockedRoleConfig = useSelector(
    (state) => state.groupAndStaffSlotMetaData?.unitLevelLockedRoles,
  )

  const siteWideLockedRoles = useSelector(
    (state) => state.userGroups?.lockedRoles,
  )

  const unitLevelLockedRoles = useMemo(
    () => lockedRoleConfig?.unitLevelLockedRoles || [],
    [lockedRoleConfig],
  )

  // Check if any locked roles are currently selected
  const hasLockedRolesCurrentlySelected = useMemo(() => {
    const disabledUnits = unitLevelLockedRoles.map(
      (role) => role.fhirResourceUrl.split('/')[1].split('-')[1],
    )
    return selectedUnits.some((unit) => disabledUnits.includes(unit.text))
  }, [selectedUnits, unitLevelLockedRoles])

  // Set isEDUnit to true if there are any unit-level locked roles
  useEffect(() => {
    setIsEDUnit(unitLevelLockedRoles.length > 0)
  }, [unitLevelLockedRoles])

  // Combine site-wide and unit-level locked roles
  const combinedLockedRoles = useMemo(() => {
    const unitLockedRoles = unitLevelLockedRoles.reduce((acc, role) => {
      return acc.concat(role.lockedRoles)
    }, [])
    return [...siteWideLockedRoles, ...unitLockedRoles]
  }, [unitLevelLockedRoles, siteWideLockedRoles])

  const matchingStaffSlots = staffSlots
    ?.filter((slot) => combinedLockedRoles.includes(slot.systemName))
    .map((slot) => slot.abbreviation)

  const { logTrackingEvent } = useAnalyticsApi()
  const orgId = useSelector((state) => state.user.userSettings.facilityCoid)

  const renderLinks = (navLinks) => {
    const allowedLinks = []

    if (user.user) {
      navLinks.forEach((link) => {
        if (
          link.allowedRoles.some((link) => user.userPermissions.includes(link))
        ) {
          allowedLinks.push(Object.assign(link, { navBarItem: true }))
        }
      })
    }
    return allowedLinks
  }

  let hasSingleUnit

  const isShiftDisabled = useMemo(() => {
    // has a single unit other than 'All'
    if (listOfUnits.length === 2) {
      hasSingleUnit = true
    }

    const currentSelectedUnits = selectedUnits
    if (hasSingleUnit) {
      return false
    } else {
      return (
        allUnitsSelected ||
        currentSelectedUnits.includes('00000000-0000-0000-0000-000000000000') ||
        currentSelectedUnits.some((unit) => unit.key === 'all') ||
        currentSelectedUnits.length === units.units.length ||
        page === 'patients' ||
        (user.userSettings.preferredUnitIds &&
          !user.userSettings.preferredUnitIds.includes(
            '00000000-0000-0000-0000-000000000000',
          ) &&
          user.userSettings.preferredUnitIds.length === units.units.length)
      )
    }
  }, [
    allUnitsSelected,
    page,
    selectedUnits,
    units.units.length,
    user.userSettings.preferredUnitIds,
  ])

  useEffect(() => {
    const orderedGroup = [
      ...formattedUserGroups.filter(
        (group) => group.text && group.text.includes('Nursing'),
      ),
      ...formattedUserGroups.filter(
        (group) =>
          group.text &&
          !group.text.includes('Nursing') &&
          !group.text.includes('Additional'),
      ),
      ...formattedUserGroups.filter(
        (group) => group.text && group.text.includes('Additional'),
      ),
    ]

    setSelectedDept(
      userSettings.preferredRoleGroup
        ? orderedGroup.filter(
            (group) => group.value === userSettings.preferredRoleGroup,
          )[0]
        : orderedGroup[0],
      setListOfDepts(orderedGroup),
    )

    let deptSelected = orderedGroup.filter(
      (group) => group.value === userSettings.preferredRoleGroup,
    )[0]
      ? orderedGroup.filter(
          (group) => group.value === userSettings.preferredRoleGroup,
        )[0]
      : orderedGroup[0]

    selectDept(deptSelected)
    if (page !== 'location') {
      dispatch({ type: 'ui/IS_DRAFT_ASSIGNMENT', payload: false })
    }

    window.addEventListener('click', (e) => {
      if (
        e.target.id !== 'unit-icon' &&
        e.target.id !== 'unit-input' &&
        e.target.id !== 'unit-dropdown-item' &&
        e.target.id !== 'unit-item-checkbox' &&
        document.getElementById('unit-dropdown') &&
        document.getElementById('unit-dropdown').classList.contains('active') &&
        document
          .getElementById('unit-dropdown')
          .classList.contains('visible') &&
        document
          .getElementById('unit-dropdown-items')
          .classList.contains('visible')
      ) {
        document.getElementById('unit-dropdown').classList.remove('active')
        document.getElementById('unit-dropdown').classList.remove('visible')
        document
          .getElementById('unit-dropdown-items')
          .classList.remove('visible')
      }
      if (
        e.target.id !== 'dept-icon' &&
        e.target.id !== 'dept-input' &&
        e.target.id !== 'dept-dropdown-item' &&
        document.getElementById('dept-dropdown') &&
        document.getElementById('dept-dropdown').classList.contains('active') &&
        document
          .getElementById('dept-dropdown')
          .classList.contains('visible') &&
        document
          .getElementById('dept-dropdown-items')
          .classList.contains('visible')
      ) {
        document.getElementById('dept-dropdown').classList.remove('active')
        document.getElementById('dept-dropdown').classList.remove('visible')
        document
          .getElementById('dept-dropdown-items')
          .classList.remove('visible')
      }
    })

    let unitIds

    const isAllSelected =
      (user.userSettings.preferredUnitIds &&
        user.userSettings.preferredUnitIds.includes(
          '00000000-0000-0000-0000-000000000000',
        )) ||
      (user.userSettings.preferredUnitIds &&
        user.userSettings.preferredUnitIds.includes('all'))

    if (isAllSelected) {
      unitIds = units.units.map((unit) => unit.id)
      setSelectedUnits([formattedUnits[0]])
      dispatch(fetchAllAssignments(authToken, siteId, ui))
    } else {
      unitIds = user.userSettings.preferredUnitIds

      setSelectedUnits(
        formattedUnits.filter((unit) =>
          selectedUnitIds.some((id) => id === unit.value),
        ),
      )

      user.userSettings.preferredUnitIds &&
        user.userSettings.preferredUnitIds.length > 0 &&
        dispatch(
          fetchLocationsWithMultipleUnits(authToken, siteId, unitIds, ui),
        )
    }

    const selUnit = units.units.filter(
      (unit) => selectedUnitIds && selectedUnitIds.some((id) => unit.id === id),
    )

    const unitFSIds = selUnit.reduce(
      (acc, unit) =>
        unit.integrationProperties &&
        unit.integrationProperties.FacilitySchedulerId !== '-1' &&
        unit.integrationProperties.FacilitySchedulerId
          ? [...acc, unit.integrationProperties.FacilitySchedulerId]
          : acc,
      [],
    )

    dispatch(
      fetchPreferredStaffPool({
        authToken: authToken,
        fsDepartmentIds: isAllSelected ? ['-1'] : [...new Set(unitFSIds)],
        siteId,
        isDraftAssignment: ui.isDraftAssignment,
      }),
    )
    setMounted(true)
  }, [])
  useEffect(() => {
    if (mounted) {
      if (stagedStaffSlotErrors?.status === 400) {
        showSnackbar()
      }
      if (!saving && publishing) {
        setPublishing(false)
      }
      if (publishing && initialPublish) {
        setInitialPublish(false)
      }
    }
  }, [mounted, publishing, saving, initialPublish, stagedStaffSlotErrors])

  const discardSlots = () => {
    const payload = {
      selStaffSlots: selectedSlots,
    }
    dispatch(discardStagedSlots(payload))
    dispatch({ type: 'ui/IS_DRAFT_ASSIGNMENT', payload: false })
    setDraftButtonSelected(false)
    setCurrentButtonSelected(true)
    dispatch({ type: 'ui/TABLE_CHECKBOX_CHECKED', payload: false })
    hideModal()
  }

  const unassignSlots = () => {
    if (ui.isDraftAssignment) {
      dispatch(
        unassignDraftAssignments(
          authToken,
          siteId,
          selectedUnitIds,
          ui,
          selectedSlots,
          selectedDept.text,
          draftLocations,
        ),
      )
    } else {
      // const eventProperties = {
      //   user34: user.user34,
      //   timeOfClick: moment().format('MM/DD/YYYY HH:mm:ss'),
      // }
      dispatch(
        assignSlots({
          data: selectedSlots,
          assignmentType: 'remove',
          selStaffMember,
        }),
      )
      dispatch(clearAllSelectedLocationsStaff())
    }
  }

  const assignmentOverlap = () => {
    if (ui.isDraftAssignment) {
      dispatch(
        saveDraftAssignments(
          authToken,
          siteId,
          selectedUnitIds,
          ui,
          selectedSlots,
          selectedDept.text,
          staffPool,
          draftLocations,
        ),
      )
    }
    dispatch({ type: 'stagedStaffSlots/ASSIGNING', payload: true })
    dispatch({
      type: 'ui/CANCEL_OVERLAP_ASSIGNMENT',
      payload: false,
    })

    const selectedStaffSlot = Object.values(selectedSlots)
    let hasPatientAssignmentOverlap = false
    let hasLocationAssignmentOverlap = false

    selectedStaffSlot.forEach((selectedSlot) => {
      if (
        selectedSlot.assignedToLocation.userId ||
        selectedSlot.assignedToPatient.userId
      ) {
        hasPatientAssignmentOverlap = true
        hasLocationAssignmentOverlap = true
      }
    })

    if (
      user.globalUserSettings.showTutorial &&
      (hasPatientAssignmentOverlap || hasLocationAssignmentOverlap)
    ) {
      showOverlapModal()
    } else {
      assigningSlots('assignmentOverlap patient track')
    }
  }
  const selectedDraftButton = (boolean) => {
    const selUnit = units.units.filter((unit) =>
      selectedUnitIds.some((id) => unit.id === id),
    )

    const unitFSIds = selUnit.reduce(
      (acc, unit) =>
        unit.integrationProperties &&
        unit.integrationProperties.FacilitySchedulerId
          ? [...acc, unit.integrationProperties.FacilitySchedulerId]
          : acc,
      [],
    )

    dispatch({ type: 'ui/IS_DRAFT_ASSIGNMENT', payload: boolean })
    setDraftButtonSelected(boolean)
    setCurrentButtonSelected(!boolean)
    if (boolean) {
      dispatch(
        fetchPreferredDraftStaffPool({
          authToken: authToken,
          fsDepartmentIds: [...new Set(unitFSIds)],
          siteId,
        }),
      )
    } else {
      dispatch(
        fetchPreferredStaffPool({
          authToken: authToken,
          fsDepartmentIds:
            selectedUnits.filter(
              (unit) => unit.value === '00000000-0000-0000-0000-000000000000',
            ).length > 0
              ? ['-1']
              : [...new Set(unitFSIds)],
          siteId,
          isDraftAssignment: ui.isDraftAssignment,
        }),
      )
    }
  }
  const assigningSlots = (location) => {
    const slotsWithAssignedUsers = selectedSlots.map((slot) => {
      const currentUser = selectedStaffMember
      const user = {
        user:
          (currentUser &&
            currentUser.firstName + ' ' + currentUser.lastName[0] + '.') ||
          '',
        userId: (currentUser && currentUser.id) || '',
        assignmentId:
          slot[`assignedTo${slot.manifest.Type}`].assignmentId || '',
      }
      return Object.assign(
        {},
        slot,
        slot.manifest.Type === 'Location'
          ? {
              assignedToLocation: user,
            }
          : { assignedToPatient: user },
      )
    })
    dispatch(clearAllSelectedLocationsStaff())

    hideOverlapModal()
    dispatch(
      assignSlots({
        data: slotsWithAssignedUsers,
        assignmentType: 'assign',
        functionCalledFrom: location,
      }),
    )
  }
  // toggle between hiding and showing the dropdown content */
  const showDropdown = () => {
    document.getElementById('unit-dropdown').classList.add('active')
    document.getElementById('unit-dropdown').classList.add('visible')
    document.getElementById('unit-dropdown-items').classList.toggle('visible')
  }
  const showDeptDropdown = () => {
    document.getElementById('dept-dropdown').classList.add('active')
    document.getElementById('dept-dropdown').classList.add('visible')
    document.getElementById('dept-dropdown-items').classList.toggle('visible')
  }

  const saveInput = (sanitizedSearchText, type) => {
    const orderedGroup = [
      ...formattedUserGroups.filter(
        (group) => group.text && group.text.includes('Nursing'),
      ),
      ...formattedUserGroups.filter(
        (group) =>
          group.text &&
          !group.text.includes('Nursing') &&
          !group.text.includes('Additional'),
      ),
      ...formattedUserGroups.filter(
        (group) => group.text && group.text.includes('Additional'),
      ),
    ]
    if (sanitizedSearchText === '') {
      if (type === 'dept') {
        setListOfDepts(orderedGroup)
        setSearchingDept(false)
        setDeptSearchText(null)
      } else {
        setListOfUnits(formattedUnits)
        setSearching(false)
        setSearchText(null)
      }
    } else {
      if (type === 'dept') {
        const filteredDepts = orderedGroup.filter((group) =>
          group.text.toLowerCase().includes(sanitizedSearchText.toLowerCase()),
        )
        setListOfDepts(filteredDepts)
        setSearchingDept(true)
        setDeptSearchText(sanitizedSearchText)
      } else {
        const filteredUnits = formattedUnits.filter((unit) =>
          unit.text.toLowerCase().includes(sanitizedSearchText.toLowerCase()),
        )
        setListOfUnits(filteredUnits)
        setSearching(true)
        setSearchText(sanitizedSearchText)
      }
    }
  }

  const selectItem = (item) => {
    showModal()
    setSearchText(null)
    setSearching(false)

    let unitIds
    const currentSelectedUnits = selectedUnits

    if (
      currentSelectedUnits.filter((unit) => unit.value === item.value).length >
      0
    ) {
      unitIds = currentSelectedUnits
        .filter((u) => u.value !== item.value)
        .map((unit) => unit.value)
    } else {
      const newSelectedUnitValues = [...currentSelectedUnits, item].map(
        (unit) => unit.value,
      )
      unitIds =
        item.value === '00000000-0000-0000-0000-000000000000'
          ? ['00000000-0000-0000-0000-000000000000']
          : newSelectedUnitValues.filter(
              (u) => u && u !== '00000000-0000-0000-0000-000000000000',
            )
    }

    const isAllSelected =
      item.value === '00000000-0000-0000-0000-000000000000'
        ? true
        : units.units.length > 0 && unitIds.length === units.units.length

    const newSelectedUnits = units.units.filter((unit) =>
      unitIds.some((id) => unit.id === id),
    )

    const unitFSIds = newSelectedUnits.reduce(
      (acc, unit) =>
        unit.integrationProperties &&
        unit.integrationProperties.FacilitySchedulerId
          ? [...acc, unit.integrationProperties.FacilitySchedulerId]
          : acc,
      [],
    )

    const newUserSettings = Object.assign({}, userSettings, {
      preferredUnitIds: unitIds,
      allUnitsSelected: isAllSelected ? true : false,
    })

    dispatch(clearAllSelectedLocationsStaff())
    dispatch(
      setUserSettings({
        authToken,
        siteId,
        userSettings: newUserSettings,
      }),
    )

    unitIds ? dispatch(selectUnit(unitIds)) : dispatch(selectUnit([]))

    if (isAllSelected && !draftButtonSelected) {
      dispatch({ type: 'user/ALL_UNITS_SELECTED', payload: true })
      dispatch(fetchAllAssignments(authToken, siteId, ui))
    } else {
      dispatch({ type: 'user/ALL_UNITS_SELECTED', payload: false })
      dispatch(fetchLocationsWithMultipleUnits(authToken, siteId, unitIds, ui))
    }

    const hasSites =
      isAllSelected &&
      sites.sites.filter((site) => site.siteId === sites.selectedSiteId)
        .length > 0

    if (draftButtonSelected) {
      dispatch(
        fetchPreferredDraftStaffPool({
          authToken,
          fsDepartmentIds: [...new Set(unitFSIds)],
          siteId,
        }),
      )
    } else {
      dispatch(
        fetchPreferredStaffPool({
          authToken,
          fsDepartmentIds:
            isAllSelected && hasSites ? ['-1'] : [...new Set(unitFSIds)],
          siteId,
          isDraftAssignment: ui.isDraftAssignment,
        }),
      )
    }

    if (selectedStaffMember !== null) {
      dispatch(clearAllSelectedStaff())
    }

    if (
      // item is currently checked
      currentSelectedUnits.filter((unit) => unit.value === item.value).length >
      0
    ) {
      // remove it from the list of checked
      setSelectedUnits(
        currentSelectedUnits.filter((unit) => unit.value !== item.value),
      )
    } else {
      // item is not currently checked and item is all
      if (item.value === '00000000-0000-0000-0000-000000000000') {
        // add all checked
        setSelectedUnits([formattedUnits[0]])
      } else if (
        // item is not all and all is already checked
        item.value !== '00000000-0000-0000-0000-000000000000' &&
        currentSelectedUnits.filter(
          (unit) => unit.value === '00000000-0000-0000-0000-000000000000',
        ).length > 0
      ) {
        // remove all from list of checked and add new item
        const filterOutAll = currentSelectedUnits.filter(
          (unit) => unit.value !== '00000000-0000-0000-0000-000000000000',
        )
        setSelectedUnits([...filterOutAll, item])
      } else {
        // add item to list of checked
        setSelectedUnits([...currentSelectedUnits, item])
      }
    }
  }

  const selectDept = (item) => {
    setDeptSearchText(null)
    setSearchingDept(false)

    const newUserSettings = Object.assign({}, userSettings, {
      preferredRoleGroup: item?.value,
    })
    setSelectedDept(item)
    dispatch(setSelectedStaffSlot([]))

    const selUnit = units.units.filter(
      (unit) => selectedUnitIds && selectedUnitIds.some((id) => unit.id === id),
    )

    const unitFSIds = selUnit.reduce(
      (acc, unit) =>
        unit.integrationProperties &&
        unit.integrationProperties.FacilitySchedulerId
          ? [...acc, unit.integrationProperties.FacilitySchedulerId]
          : acc,
      [],
    )

    dispatch(
      setUserSettings({
        authToken,
        siteId,
        userSettings: newUserSettings,
      }),
    )
    dispatch(selectUserGroup(item?.value))
    dispatch({
      type: 'ui/SET_SELECTED_DEPARTMENT',
      payload: item?.text,
    })
    if (ui.isDraftAssignment) {
      dispatch(
        fetchDraftAssignments(
          authToken,
          siteId,
          selectedUnitIds,
          ui,
          locations.locations,
          item.text,
        ),
      )
      dispatch(
        fetchPreferredDraftStaffPool({
          authToken,
          fsDepartmentIds: [...new Set(unitFSIds)],
          siteId,
        }),
      )
    }
    if (selectedStaffMember !== null) {
      dispatch(clearAllSelectedStaff())
    }
  }

  const showModal = () => {
    if (disabledNav === true) {
      setShowUnpublishedChangesModal(true)
    }
  }

  const hideModal = () => {
    setShowUnpublishedChangesModal(false)
  }

  const hideOverlapModal = () => {
    setShowOverlapAssignment(false)
  }
  const showOverlapModal = () => {
    setShowOverlapAssignment(true)
  }

  const hideSnackbar = () => {
    setShowErrorSnackbar(false)
  }

  const showSnackbar = () => {
    setShowErrorSnackbar(true)
  }

  const handleHover = () => {
    setIsHovered(!isHovered)
  }

  const hideAssignToAllModal = () => {
    setShowAssignToAllUnits(false)
  }
  const showAssignToAllModal = () => {
    setShowAssignToAllUnits(true)
  }

  const hidePublishModal = (boolean) => {
    if (boolean === false) {
      selectedDraftButton(boolean)
      setShowingPublishModal(false)
    } else {
      setShowingPublishModal(false)
    }
  }
  const showPublishModal = () => {
    setShowingPublishModal(true)
  }

  const handlePublishState = () => {
    setPublishing(true)
  }

  const userPath = window.location.pathname
  const eventProperties = {
    user34: user.user34,
    timeOfClick: moment().format('MM/DD/YYYY HH:mm:ss'),
  }
  const globalSettingsConfig = {
    authToken,
    siteId: sites.selectedSiteId,
    globalSettings: user.globalUserSettings,
  }

  const userSettingsConfig = {
    authToken,
    siteId: sites.selectedSiteId,
    userSettings: user.userSettings,
  }

  const showHideErrorSnackbarClassName = showErrorSnackbar
    ? 'modal display-block'
    : 'modal display-none'

  let selectedSlotHasAssignment
  selectedSlots.forEach((slot) => {
    if (
      slot[page === 'locations' ? 'assignedToLocation' : 'assignedToPatient']
        .user
    ) {
      selectedSlotHasAssignment = true
    }
  })

  const hasAssignedSelections = rest.hasAssignedSelections
  const hasUnassignedSelections = rest.hasUnassignedSelections
  const selStaffMember = selectedStaffMember
  const hasStagedStaffSlots = rest.hasStagedStaffSlots
  const userHasPermissionToMakeAssignments =
    (user.userPermissions.includes('AssignSelf') &&
      user.user34 === selectedStaffMember &&
      selectedStaffMember.hca34) ||
    user.userPermissions.includes('AssignOthers')

  useEffect(() => {
    const staffSlotNames = staffSlots?.map((slot) => slot.systemName) || []
    const lockedRoleNames = combinedLockedRoles.map((role) => role)
    const selectedUnitValues = selectedUnits.map((unit) => unit.value)

    // Check if any staffSlotNames match any lockedRoleNames
    const containsSameRoles = staffSlotNames.some((role) =>
      lockedRoleNames.includes(role),
    )

    // Determine if the component should be read-only based on various conditions

    const allUnitsSelectedWithED =
      user.userSettings.preferredUnitIds?.includes(
        '00000000-0000-0000-0000-000000000000',
      ) &&
      containsSameRoles &&
      unitLevelLockedRoles.length > 0

    const setReadOnly =
      containsSameRoles &&
      (siteWideLockedRoles.length > 0 ||
        (hasLockedRolesCurrentlySelected && unitLevelLockedRoles.length > 0) ||
        allUnitsSelectedWithED)

    setIsReadOnly(setReadOnly)
  }, [
    user.userSettings.preferredUnitIds,
    hasLockedRolesCurrentlySelected,
    combinedLockedRoles,
    page,
    staffSlots,
    selectedUnits,
  ])

  useEffect(() => {
    if (draftButtonSelected) {
      const thereAreActiveDrafts = Object.values(draftAssignments).some(
        (assignment) => assignment.assignments?.length > 0,
      )
      setHasDraftAssignment(thereAreActiveDrafts)
    }
  }, [draftAssignments, draftButtonSelected, setHasDraftAssignment])

  const showUnassignButton =
    selectedSlots &&
    selectedSlots.length > 0 &&
    selectedSlotHasAssignment &&
    userHasPermissionToMakeAssignments &&
    !hasAssignedSelections

  const showDiscardButton =
    !publishing &&
    !assigning &&
    (hasStagedStaffSlots || (hasDraftAssignment && draftButtonSelected)) &&
    !hasAssignedSelections &&
    (!isReadOnly || combinedLockedRoles)

  const showAssignButton =
    !assigning &&
    selStaffMember &&
    (hasAssignedSelections || hasUnassignedSelections) &&
    userHasPermissionToMakeAssignments &&
    !hasAssignedSelections

  const showPublishButton =
    !assigning &&
    !publishing &&
    (hasStagedStaffSlots || (hasDraftAssignment && draftButtonSelected)) &&
    !hasAssignedSelections &&
    userHasPermissionToMakeAssignments &&
    (!isReadOnly || combinedLockedRoles)

  /**
   * Logging Events
   */

  const ctaLastPath = localStorage.getItem('ctaLastPath')

  const trackingAssignClicks = () => {
    if (ui.isDraftAssignment & ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[1].Name,
          eventName: Events.Locations.Screen[1].Events.Assign_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (
      !ui.isDraftAssignment &&
      ctaLastPath.includes('locationassignments')
    ) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[0].Name,
          eventName: Events.Locations.Screen[0].Events.Assign_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName: Events.Patients.Screen.Events.Assign_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }
  const trackingPublishClicks = () => {
    if (ui.isDraftAssignment & ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[1].Name,
          eventName: Events.Locations.Screen[1].Events.Publish_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (
      !ui.isDraftAssignment &&
      ctaLastPath.includes('locationassignments')
    ) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[0].Name,
          eventName: Events.Locations.Screen[0].Events.Publish_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName: Events.Patients.Screen.Events.Publish_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }
  const trackingUnassignClicks = () => {
    if (ui.isDraftAssignment & ctaLastPath.includes('locationassignments')) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[1].Name,
          eventName: Events.Locations.Screen[1].Events.Unassign_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else if (
      !ui.isDraftAssignment &&
      ctaLastPath.includes('locationassignments')
    ) {
      logTrackingEvent([
        {
          module: Events.Locations.Module,
          screen: Events.Locations.Screen[0].Name,
          eventName: Events.Locations.Screen[0].Events.Unassign_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    } else {
      logTrackingEvent([
        {
          module: Events.Patients.Module,
          screen: Events.Patients.Screen.Name,
          eventName: Events.Patients.Screen.Events.Unassign_ButtonClick,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    }
  }

  const history = useHistory()

  const trackingReportClicks = () => {
    history.listen((location) => {
      let ctaLastPath = location.pathname
      let screenIndex
      let eventName

      if (ctaLastPath.includes('reports/snapshot')) {
        screenIndex = 0
        eventName = Events.Reports.Screen[0].Events.Snapshot_ReportClick
      } else if (ctaLastPath.includes('reports/assignmentaudit')) {
        screenIndex = 1
        eventName = Events.Reports.Screen[1].Events.Assignment_Audit_ReportClick
      } else if (ctaLastPath.includes('reports/missingassignments')) {
        screenIndex = 2
        eventName =
          Events.Reports.Screen[2].Events.Missing_Assignments_ReportClick
      }

      logTrackingEvent([
        {
          module: Events.Reports.Module,
          screen: Events.Reports.Screen[screenIndex]?.Name,
          eventName,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    })
  }

  const trackingManageClicks = () => {
    history.listen((location) => {
      let ctaLastPath = location.pathname
      let screenIndex
      let eventName

      if (ctaLastPath.includes('manage/users')) {
        screenIndex = 0
        eventName = Events.Manage.Screen[0].Events.Users_TabClick
      } else if (ctaLastPath.includes('manage/locations')) {
        screenIndex = 1
        eventName = Events.Manage.Screen[1].Events.Locations_TabClick
      } else if (ctaLastPath.includes('manage/patients')) {
        screenIndex = 2
        eventName = eventName = Events.Manage.Screen[2].Events.Patients_TabClick
      }

      logTrackingEvent([
        {
          module: Events.Manage.Module,
          screen: Events.Manage.Screen[screenIndex]?.Name,
          eventName,
          eventType: 'action',
          org: {
            orgId: orgId,
            orgLevel: 'Facility',
          },
        },
      ])
    })
  }

  if (page === 'locations' || page === 'patients') {
    return (
      <>
        {isReadOnly ? (
          <ReadOnlyBanner
            isEDUnit={isEDUnit}
            staffSlotAbbreviations={matchingStaffSlots}
          />
        ) : (
          <></>
        )}
        <SubNav page={page}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: '0 15px 0 15px',
              paddingTop: '10px',
              paddingBottom: '28px',
            }}
          >
            <OverwritingAssignment
              showOverlapAssignment={showOverlapAssignment}
              assignSlots={assigningSlots}
              hideOverlapModal={hideOverlapModal}
              globalSettingsConfig={globalSettingsConfig}
              userSettingsConfig={userSettingsConfig}
              eventProperties={eventProperties}
              dispatch={dispatch}
            />

            <ErrorSnackbar
              stagedStaffSlotErrors={stagedStaffSlotErrors}
              showHideErrorSnackbarClassName={showHideErrorSnackbarClassName}
              hideSnackbar={hideSnackbar}
            />
            <UnpublishedChanges
              showUnpublishedState={showUnpublishedChangesModal}
              dispatch={dispatch}
              hideModal={hideModal}
              stagedStaffSlots={stagedStaffSlots}
              page={page}
              siteId={sites.selectedSiteId}
              selectedUnitIds={selectedUnitIds}
              authToken={authToken}
              ui={ui}
              eventProperties={eventProperties}
              allUnitsSelected={allUnitsSelected}
            />
            <PublishDraftModal
              showPublishModalState={showingPublishModal}
              hidePublishModal={hidePublishModal}
              showPublishModal={showPublishModal}
              publishing={publishing}
              handlePublishState={handlePublishState}
              eventProperties={eventProperties}
              allUnitsSelected={allUnitsSelected}
              stagedStaffSlots={stagedStaffSlots}
              page={page}
              siteId={siteId}
              selectedUnitIds={selectedUnitIds}
              hideModal={hideModal}
              authToken={authToken}
              ui={ui}
              dispatch={dispatch}
              draftLocations={draftLocations}
            />

            <AssigningToAllUnits
              showAssignToAllUnits={showAssignToAllUnits}
              hideAssignToAllModal={hideAssignToAllModal}
              showAssignToAllModal={showAssignToAllModal}
              publishing={publishing}
              handlePublishState={handlePublishState}
              eventProperties={eventProperties}
              allUnitsSelected={allUnitsSelected}
              stagedStaffSlots={stagedStaffSlots}
              page={page}
              siteId={siteId}
              selectedUnitIds={selectedUnitIds}
              hideModal={hideModal}
              authToken={authToken}
              ui={ui}
              dispatch={dispatch}
              draftLocations={draftLocations}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: '0 15px 0 15px',
              }}
            >
              <SubNavItem onClick={() => showModal()}>
                <div>Dept</div>
                {selectedGroup && (
                  <DeptDropdown
                    showDropdown={showDeptDropdown}
                    saveInput={saveInput}
                    searching={searchingDept}
                    searchText={deptSearchText}
                    selectedDept={selectedDept}
                    options={listOfDepts}
                    selectDept={selectDept}
                    isDraft={ui.isDraftAssignment}
                  />
                )}
              </SubNavItem>
              <SubNavItem
                style={{ paddingLeft: '30px' }}
                onClick={() => showModal()}
              >
                <div
                  style={{
                    paddingRight: '8px',
                  }}
                >
                  UNIT
                </div>
                {(allUnitsSelected || formattedUnits) && (
                  <>
                    <UnitDropdown
                      showDropdown={showDropdown}
                      saveInput={saveInput}
                      searching={searching}
                      searchText={searchText}
                      selectedUnits={selectedUnits}
                      units={listOfUnits}
                      selectItem={selectItem}
                      isDraft={ui.isDraftAssignment}
                    />
                  </>
                )}
              </SubNavItem>
              {!allUnitsSelected && (
                <SubNavItem
                  style={{ paddingLeft: '30px' }}
                  disabled={isShiftDisabled || page === 'patients'}
                >
                  <div
                    style={{
                      paddingRight: '8px',
                    }}
                  >
                    SHIFT
                  </div>
                  <div
                    className="neu-button-toggles neu-button-toggles__button"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <DraftButtons
                      style={{
                        borderLeftWidth: '3px',
                        borderRadius: '5px 0 0 5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '112px',
                        height: '41px',
                        marginTop: 0,
                        textTransform: 'capitalize',
                      }}
                      className="neu-text--product"
                      draftButtonSelected={currentButtonSelected}
                      onClick={() => {
                        dispatch(
                          fetchLocationsWithMultipleUnits(
                            authToken,
                            siteId,
                            selectedUnitIds,
                            ui,
                          ),
                        )
                        dispatch(setSelectedStaffSlot([]))
                        selectedDraftButton(false)
                      }}
                    >
                      Current
                    </DraftButtons>

                    <DraftButtons
                      style={{
                        borderRadius: '0 5px 5px 0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '112px',
                        marginTop: 0,
                        textTransform: 'capitalize',
                      }}
                      className="neu-text--product"
                      disabled={
                        allUnitsSelected ||
                        page === 'patients' ||
                        (selectedUnits &&
                          selectedUnits[0] &&
                          selectedUnits[0].id ===
                            '00000000-0000-0000-0000-000000000000')
                      }
                      draftButtonSelected={draftButtonSelected}
                      onClick={() => {
                        if (stagedStaffSlots.length > 0) {
                          setShowUnpublishedChangesModal(true)
                        }
                        selectedDraftButton(true)
                        dispatch(setSelectedStaffSlot([]))
                        dispatch(
                          fetchLocationsWithMultipleUnits(
                            authToken,
                            siteId,
                            selectedUnitIds,
                            ui,
                            'draft',
                          ),
                        )

                        logTrackingEvent([
                          {
                            module: Events.Locations.Module,
                            screen: Events.Locations.Screen[1].Name,
                            eventName:
                              Events.Locations.Screen[1].Events
                                .New_Draft_TabClick,
                            eventType: 'action',
                            org: {
                              orgId: orgId,
                              orgLevel: 'Facility',
                            },
                          },
                        ])
                      }}
                    >
                      {draftButtonSelected ? 'Draft' : 'New Draft'}
                    </DraftButtons>
                  </div>
                </SubNavItem>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                height: '45px',
              }}
            >
              <SubNavButtonContainer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '45px',
                  }}
                >
                  <>
                    <AssignmentActionButtons
                      color={'#CE2130'}
                      style={{ backgroundColor: 'transparent' }}
                      disabled={!showUnassignButton}
                      onClick={() => {
                        trackingUnassignClicks()
                        unassignSlots()
                      }}
                    >
                      Unassign
                    </AssignmentActionButtons>
                    <AssignmentActionButtons
                      color={'#00558C'}
                      disabled={!showAssignButton}
                      onClick={
                        showAssignButton
                          ? () => {
                              trackingAssignClicks()
                              assignmentOverlap()
                            }
                          : null
                      }
                    >
                      {assigning && !ui.cancelOverlapAssignment ? (
                        <Loading square="20px" />
                      ) : (
                        'Assign'
                      )}
                    </AssignmentActionButtons>

                    {showDiscardButton &&
                    (showAssignButton || showUnassignButton) ? (
                      <div
                        style={{
                          borderLeft: 'solid 2px lightgrey',
                          marginTop: '10px',
                          marginLeft: '5px',
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    <AssignmentActionButtons
                      color={'#CE2130'}
                      style={{ backgroundColor: 'transparent' }}
                      disabled={!showDiscardButton}
                      onClick={() => {
                        discardSlots()
                        ui.isDraftAssignment &&
                          dispatch(
                            discardDraftAssignments(
                              authToken,
                              siteId,
                              selectedUnitIds,
                              ui,
                              draftLocations,
                            ),
                          )
                        selectedDraftButton(false)
                      }}
                    >
                      {ui.isDraftAssignment ? 'Discard Draft' : 'Discard'}
                    </AssignmentActionButtons>
                    <PublishButton
                      className={
                        !showPublishButton || publishing ? '#54575A' : 'white'
                      }
                      color={'#FFF'}
                      backgroundColor={'#E05929'}
                      publishing={publishing}
                      disabled={!showPublishButton || publishing}
                      onClick={
                        showPublishButton
                          ? () => {
                              trackingPublishClicks()
                              if (
                                allUnitsSelected &&
                                ui.tableCheckboxIsChecked
                              ) {
                                showAssignToAllModal()
                              } else if (ui.isDraftAssignment) {
                                showPublishModal()
                              } else {
                                setPublishing(true)
                                publishFunction(
                                  stagedStaffSlots,
                                  page,
                                  siteId,
                                  selectedUnitIds,
                                  hideModal,
                                  authToken,
                                  ui,
                                  allUnitsSelected,
                                )
                              }
                              //TODO replace below with future tracking

                              // amplitude
                              // .getInstance()
                              // .logEvent(
                              //   'PRIMARY_PUBLISH_BUTTON_CLICKED',
                              //   eventProperties,
                              // )
                            }
                          : null
                      }
                      onMouseEnter={handleHover}
                      onMouseLeave={handleHover}
                    >
                      {publishing ? <Loading square="20px" /> : 'Publish'}
                    </PublishButton>
                  </>
                </div>
              </SubNavButtonContainer>
            </div>
          </div>
        </SubNav>
      </>
    )
  } else {
    //TODO refactor neutron tab is active and tablist tab
    const links =
      page === 'manage' ? renderLinks(ManageLinks) : renderLinks(ReportsLinks)

    return (
      <SubNav page={page}>
        <div>
          {links.map((item) => {
            return (
              <NavLink
                key={item.link}
                to={item.link}
                onClick={(e) => {
                  dispatch({ type: 'user/CLEAR_SEARCH' })
                  if (page === 'reports') {
                    trackingReportClicks()
                    handleSlidingPane()
                    dispatch(clearReportData())
                    dispatch({
                      type: 'ui/CLEAR_MISSING_ASSIGNMENT_DATA',
                    })
                    dispatch({
                      type: 'ui/CLEAR_AUDIT_TYPE_AND_TIME',
                    })
                    dispatch({
                      type: 'ui/CLEAR_SNAPSHOT_DATE_AND_TIME',
                    })
                    dispatch({ type: 'ui/CLEAR_REPORT_KEYWORD' })
                  }
                  if (page === 'manage') {
                    trackingManageClicks()
                  }
                  if (item.navItemText === 'Patients') {
                    dispatch({
                      type: 'assignments/FETCH_ADMIN_LOCATIONS_RECEIVED',
                      payload: [],
                    })
                  }
                }}
                activeClassName={
                  userPath === item.link ? 'is-active' : 'navItemText'
                }
              >
                <TabButton
                  className={
                    'navItemText ' + (userPath === item.link ? 'is-active' : '')
                  }
                >
                  <NavItemText
                    className={userPath === item.link ? 'is-active' : ''}
                  >
                    {item.navItemText}
                  </NavItemText>
                </TabButton>
              </NavLink>
            )
          })}
        </div>
      </SubNav>
    )
  }
}

const mapReduxStateToProps = function (state, props) {
  return {
    stagedStaffSlots: state.stagedStaffSlots,
    saving: state.stagedStaffSlots.saving,
    sites: state.sites,
    userGroups: state.userGroups.userGroups,
    formattedUserGroups: state.formattedUserGroups,
    preferredStaff: state.facilitySchedulerUsers.preferredStaff,
    allUnitsSelected: state.user.allUnitsSelected,
    ui: state.ui,
    locations: state.locations,
    draftLocations: state.draftAssignments.draftLocations,
    draftAssignments: state.draftAssignments.draftAssignments,
    units: state.units,
    ...unitandUserGroupDropdownSelector(state, props),
    staffPool: state.staffPool,
    user: state.user,
  }
}

export default connect(mapReduxStateToProps)(SubNavigation)
