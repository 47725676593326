/**
 * The environment variable for the application environment.
 * @type {string}
 */
const environment = process.env.REACT_APP_ENV

if (!environment) {
  throw Error('App environment variable is not set')
}

const serverUrls = {
  local: 'https://careteam-qa.hcahealthcare.cloud/api/',
  devcloud: 'https://careteam-dev.hcahealthcare.cloud/api/',
  qacloud: 'https://careteam-qa.hcahealthcare.cloud/api/',
  prodcloud: 'https://careteam.hcahealthcare.cloud/api/',
  default: 'devurl.com',
}

const _serverUrl = serverUrls[environment.toLowerCase()] || serverUrls.default

const assignEnvs = () => ({
  _reactAppAnalyticsAPIKey: process.env.REACT_APP_ANALYTICS_API_KEY,
  _azureRoleName: process.env.REACT_APP_AZURE_ROLE_NAME,
})

const envs = assignEnvs()

/**
 * The exported environment variables.
 * @type {Object}
 */
export default {
  _serverUrl,
  _type: environment.toLowerCase(),
  ...envs,
}
