export const packageAssignment = ({
  data,
  assignment,
  page,
  selectedStaffMember,
}) => {
  const base = {
    unitFhirResourceUrl: data.location.unitFhirResourceUrl,
    UnitId: data.location.unitId,
    uuid: data.uuid,
    assignedToLocation: assignment.assignedToLocation,
    assignedToPatient: assignment.assignedToPatient,
    manifest: {
      Action: '', //"Insert/Update/Delete", ONLY after ASSIGN OR UNASSIGN
      id: '', //{assignment id - optional on Insert, required for Update and Delete},
      UserId: '', //{User Id being assigned},
      Type: '', //"Location/Patient",
      EntityId: '', //{id of the Location/Patient being assigned to},
      AssignmentSlot: {
        DisplayName: '', //{Display name given by the assignment slot configuration applicable},
        SystemName: '', //{System name given by the assignment slot configuration applicable},
        Abbreviation: '', //{Abbreviation given by the assignment slot configuration applicable}
      },
    },
  }
  const id =
    (assignment.assignedToPatient && assignment.assignedToPatient.userId) ||
    (assignment.assignedToLocation && assignment.assignedToLocation.userId)
  const UserId = (selectedStaffMember && selectedStaffMember.id) || ''
  const Type = page.charAt(0).toUpperCase() + page.slice(1)
  const EntityId = data[page].id
  const AssignmentSlot = {
    DisplayName: assignment.displayName,
    SystemName: assignment.systemName,
    Abbreviation: assignment.abbreviation,
  }

  return Object.assign({}, base, {
    manifest: { id, UserId, Type, EntityId, AssignmentSlot },
  })
}
