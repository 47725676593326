/**
 * External Imports
 */
import { useEffect, useMemo, useState } from 'react'
import jwt_decode from 'jwt-decode'
import $ from 'jquery'
import { v4 as uuidv4 } from 'uuid'
import packageJson from '../package.json'
import { useAnalyticsApi } from '@shared-web-analytics/react/dist'
import { useDispatch } from 'react-redux'
import {
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  BaseTokenRequestHandler,
  AuthorizationNotifier,
  TokenRequest,
  GRANT_TYPE_AUTHORIZATION_CODE,
} from '@openid/appauth'
import { NeuApp } from '@neutron/react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * Internal Imports
 */
import store from './redux/store'
import { saveUserToken, saveUser34 } from './redux/actions/user'
import { getUserSites } from './redux/actions/sites'
import Unauthorized from './components/reusableComponents/errorPages/401'
import GateKeeper from './components/auth/GateKeeper'
import environment from './environment'
import configInfo from './pingConfig.json'

/**
 * Styling Imports
 */
import './App.scss'
import './index.css'
import './customStyles.scss'

const App = () => {
  const [responseReturned, setResponseReturned] = useState(true)

  const { setTrackingSessionId, setTrackingUserId, startAnalytics } =
    useAnalyticsApi()

  const analyticsENV = useMemo(() => {
    const envMap = {
      local: 'local',
      devcloud: 'dev',
      qacloud: 'qa',
      prodcloud: 'production',
    }
    return envMap[environment._type] || 'local'
  }, [])

  useEffect(() => {
    startAnalytics(
      'CTA',
      packageJson.version,
      process.env.REACT_APP_ANALYTICS_API_KEY,
      analyticsENV,
    )
  }, [analyticsENV])

  const location = useLocation()
  const dispatch = useDispatch()

  window.$ = $

  const developmentEnvironment = process.env.REACT_APP_ENV
  const devENV = developmentEnvironment.toLowerCase()

  useEffect(() => {
    if (location.pathname !== '/redirect') {
      dispatch({
        type: 'user/SET_CURRENT_PATH',
        payload: location.pathname,
      })
    }
  }, [location.pathname, dispatch])

  const envRedirect = window.location.origin + '/redirect'
  let client_id
  let openIdConnectUrl
  if (devENV.includes('local')) {
    openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
    client_id = configInfo.devqaClient_id
  } else if (devENV.includes('dev')) {
    openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
    client_id = configInfo.devqaClient_id
  } else if (devENV.includes('qa')) {
    openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
    client_id = configInfo.devqaClient_id
  } else if (devENV.includes('prod')) {
    openIdConnectUrl = configInfo.prodOpenIdConnectUrl
    client_id = configInfo.prodClient_id
  } else {
    console.warn('No environment set')
  }
  const redirect_uri = envRedirect

  useEffect(() => {
    const fetchAndSetupAuthorization = async () => {
      const res = await fetchConfig()
      const notifier = new AuthorizationNotifier()
      const authorizationHandler = new RedirectRequestHandler()

      // Set notifier to deliver responses
      authorizationHandler.setAuthorizationNotifier(notifier)

      // Set a listener to listen for authorization responses
      notifier.setAuthorizationListener((request, response) => {
        console.log('Authorization request complete')
        if (response) {
          response.request = request
          response.response = response
          const code = response.code

          tokenRequest(request, res, code)
        }
      })

      // This sets up the listener
      authorizationHandler.completeAuthorizationRequestIfPossible()
    }

    fetchAndSetupAuthorization()
  }, [])

  const tokenRequest = (req, configuration, code) => {
    if (code) {
      let extras = {}
      if (req && req.internal) {
        extras['code_verifier'] = req.internal['code_verifier']
      }
      req = new TokenRequest({
        client_id,
        redirect_uri,
        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
        code,
        refresh_token: undefined,
        extras,
      })
    }
    if (req) {
      const tokenHandler = new BaseTokenRequestHandler()
      const tokenResponse = {}
      tokenHandler

        .performTokenRequest(configuration, req)
        .then((response) => {
          if (response) {
            // copy over new fields
            tokenResponse.accessToken = response.accessToken
            tokenResponse.issuedAt = response.issuedAt
            tokenResponse.expiresIn = response.expiresIn
            tokenResponse.tokenType = response.tokenType
            tokenResponse.scope = response.scope
          } else {
            tokenResponse.res = response
            setResponseReturned(false)
          }

          // unset code, so we can do refresh token exchanges subsequently
          code = undefined

          // THIS IS FOR SENDING ACCESS TOKEN TO BACKEND
          const decodedToken = jwt_decode(response.accessToken)

          const user34 = decodedToken.subject

          const accessToken = 'bearer ' + response.accessToken
          const authToken = accessToken
          localStorage.setItem('auth token', authToken)
          store.dispatch(saveUserToken(authToken))
          store.dispatch(saveUser34(user34))
          store.dispatch(getUserSites({ user34, authToken }))
          // tracking
          const sessionId = uuidv4()
          setTrackingSessionId(uuidv4())
          setTrackingUserId(user34)
          sessionStorage.setItem('sessionId', sessionId)
        })
        .catch(function (error) {
          console.log('Something bad happened', error)
        })
    }
  }

  const fetchConfig = () => {
    return AuthorizationServiceConfiguration.fetchFromIssuer(openIdConnectUrl)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('Something bad happened', error)
      })
  }

  return responseReturned ? (
    <NeuApp>
      <GateKeeper style={{ height: '100%' }} />
    </NeuApp>
  ) : (
    <Unauthorized />
  )
}

// export default withAITracking(reactPlugin, App)
export default App
