const ReadOnlyBanner = ({ isEDUnit, staffSlotAbbreviations }) => {
  let message
  if (isEDUnit) {
    const uniqueAbbreviations = [...new Set(staffSlotAbbreviations)]
    const formattedAbbreviations = uniqueAbbreviations.join(', ')
    if (uniqueAbbreviations.length > 1) {
      const lastAbbreviation = uniqueAbbreviations.pop()
      const formattedAbbreviationsWithAnd = `${uniqueAbbreviations.join(
        ', ',
      )} and ${lastAbbreviation}`
      message = `${formattedAbbreviationsWithAnd} roles are 'read only' for ED.`
    } else {
      message = `${formattedAbbreviations} roles are 'read only' for ED.`
    }
  } else {
    message = 'Selected units are currently in "read only" access.'
  }

  return (
    <div
      className="readOnly neu-text--caption"
      style={{
        backgroundColor: '#EFF8FD',
        textAlign: 'center',
        padding: '10px',
        boxShadow: '1px 2px 5px 1px !important',
      }}
    >
      {message}
    </div>
  )
}

export default ReadOnlyBanner
